import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import Head from "../components/head";
import { GatsbyImage } from "gatsby-plugin-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { MARKS, BLOCKS, INLINES } from "@contentful/rich-text-types";
import SyntaxHighlighter from "react-syntax-highlighter";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import OkrExampleCard from "../components/OkrExampleCard";

export const query = graphql`
	query {
		allContentfulOkrExample {
			edges {
				node {
					tag {
						slug
						title
						id
					}
					id
					keyResults
					objective
					slug
					title
				}
			}
		}
		site {
			siteMetadata {
				title
				description
				siteUrl
			}
		}
		allContentfulTag(sort: { fields: updatedAt, order: DESC }) {
			edges {
				node {
					id
					title
					slug
					okr_example {
						id
					}
				}
			}
		}
		contentfulRichText(slug: { eq: "okr-examples" }) {
			id
			body {
				raw
			}
		}
	}
`;

const CategoryPage = (props) => {
	const site = props.data.site;
	const examples = props.data.allContentfulOkrExample;
	const tag = props.data.allContentfulTag;
	const body = props.data.contentfulRichText.body;
	const description =
		"Coming up with your OKR can be a difficult task. To help you, we've collected examples across company types, teams and roles.";

	const options = {
		renderNode: {
			"embedded-asset-block": (node) => {
				return (
					<>
						<GatsbyImage
							image={node.data.target.gatsbyImageData}
							alt={node.data.target.title}
						/>
					</>
				);
			},
			[BLOCKS.QUOTE]: (node, children) => (
				<blockquote className="p-4 bg-slate-200 text-slate-800 mb-4 rounded group:text-red-800">
					{children}
				</blockquote>
			),
			[BLOCKS.HEADING_2]: (node, children) => {
				return (
					<h2 className="mt-8 mb-4 text-4xl font-bold text-slate-800">
						{children}
					</h2>
				);
			},
			[BLOCKS.HEADING_3]: (node, children) => {
				return (
					<h3 className="mb-4 mt-6 text-3xl font-bold text-slate-900">
						{children}
					</h3>
				);
			},
			[BLOCKS.PARAGRAPH]: (node, children) => {
				if (node.content[0].marks.find((x) => x.type === "blockquote")) {
					return <blockquote>{node.data.value}</blockquote>;
				} else {
					return (
						<p className="text-slate-800 text-lg leading-7 mb-4 group">
							{children}
						</p>
					);
				}
			},
			[BLOCKS.CODE]: (text) => {
				return <SyntaxHighlighter style={a11yDark}>{text}</SyntaxHighlighter>;
			},
			[BLOCKS.LIST_ITEM]: (node, children) => {
				return (
					<li
						key={node.id}
						className="list-disc ml-3 mt-1.5 text-thin text-slate-800">
						{children}
					</li>
				);
			},
			[INLINES.HYPERLINK]: (node) => {
				return (
					<a
						href={node.data.uri}
						target={`${
							node.data.uri.startsWith(site.siteMetadata.siteUrl)
								? "_self"
								: "_blank"
						}`}
						rel={`${
							node.data.uri.startsWith(site.siteMetadata.siteUrl)
								? ""
								: "noopener noreferrer"
						}`}>
						{node.content[0].value}
					</a>
				);
			},
		},
		renderMark: {
			[MARKS.CODE]: (text) => {
				return <SyntaxHighlighter style={a11yDark}>{text}</SyntaxHighlighter>;
			},
		},
	};

	const tagClick = (label) => {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: "tag_item_clicked",
			properties: {
				text: label,
			},
		});
	};

	return (
		<Layout>
			<Head
				title={`OKR examples -> Browse +100 examples across companies, teams & roles | ${props.data.site.siteMetadata.title}`}
				description={description}
				url={`${props.data.site.siteMetadata.siteUrl}/okr-examples/`}
			/>

			<main className="max-w-4xl mx-auto mt-4">
				<div className="my-8 sm:my-12 md:my-16">
					<h1 className="text-4xl font-bold tracking-tight text-slate-900 sm:text-5xl md:text-6xl">
						<span className="block xl:inline">OKR Examples</span>
					</h1>
					<p className="mt-3 text-base text-slate-500 sm:mt-5 sm:text-lg md:mt-5 md:text-xl">
						{description}
					</p>
					<div className="flex flex-wrap flex-row mt-4">
						{tag.edges.length > 0 &&
							tag.edges.map((edge) => {
								return (
									<Link
										to={`/okr-examples/${edge.node.slug}/`}
										key={edge.node.id}
										onClick={() => tagClick(edge.node.title)}
										className="mr-1 mb-2 rounded-full hover:cursor-pointer text-slate-100 hover:bg-indigo-500 active:bg-indigo-700 bg-indigo-600 px-3 py-1 text-sm font-medium">
										{`${edge.node.title} ${
											edge.node.okr_example
												? "(" + edge.node.okr_example.length + ")"
												: ""
										}`}
									</Link>
								);
							})}
					</div>
				</div>

				<div className="grid grid-cols-1 md:grid-cols-2 gap-4">
					{examples.edges.map((edge) => {
						return <OkrExampleCard key={edge.node.id} edge={edge} />;
					})}
				</div>

				<div
					className="mt-4 md:mt-8 md:my-16 lg:my-20
        prose-p:text-lg prose-p:my-6 prose-blockquote:my-4 prose-blockquote:bg-indigo-500 prose-blockquote:text-white prose-blockquote:mb-4 prose-blockquote:rounded prose-blockquote:px-4 prose-blockquote:py-1
        prose-h2:mt-8 prose-h2:mb-4 prose-h2:text-4xl prose-h2:font-bold prose-h2:text-slate-800
        prose-h3:mb-4 prose-h3:mt-6 prose-h3:text-3xl prose-h3:font-bold prose-h3:text-slate-900
        prose-li:list-disc prose-li:ml-3 prose-li:mt-1.5 prose-li:text-thin prose-li:text-slate-800
        prose-a:text-indigo-600 prose-a:underline">
					{renderRichText(body, options)}
				</div>
			</main>
		</Layout>
	);
};

export default CategoryPage;
